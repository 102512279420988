<template>
  <DirektoratForm mode="Tambah" module="Direktorat"> </DirektoratForm>
</template>

<script>
import DirektoratForm from './form';

const DirektoratAdd = {
  name: 'DirektoratAdd',
  components: { DirektoratForm },
};

export default DirektoratAdd;
</script>
